<route>
{
  "name": "personal-photo",
  "meta": {
    "title": "Set personal photo"
  }
}
</route>

<template>
  <div class="personal-photo">
    <filling-header icon="3" title="Personal photo" step="3/3" :done="isStepDone" />

    <div class="mb-16 text2">
      {{$t('personal-photo.add_your_photo_to_boost')}}
    </div>

    <div class="mxw-280 mb-24">
      <img
        v-show="avatarUrl && !avatarLoading"
        class="mxw-280 mnh-280 br50 mb-24 flex-center"
        :src="avatarUrl"
        @load="handlePhotoLoad"
        @error="handlePhotoLoadError"
      />
      <ui-loader v-if="avatarLoading" class="mnh-280 mnw-280 mb-24" />
      <img v-else-if="!avatarUrl" class="mb-24" src="@/assets/images/avatar.svg" />

      <avatar-cropper
        :labels="labels"
        :upload-url="uploadUrl"
        :upload-headers="uploadHeaders"
        :output-options="uploadOutputOptions"
        output-mime="image/jpeg"
        :output-quality="0.2"
        upload-form-name="photo"
        trigger="#pick-avatar"
        @submit="handleSubmit"
        @uploaded="handleUploaded"
        @error="handleError"
      />

      <ui-button id="pick-avatar" class="mb-8">{{$t('personal-photo.take_or_upload_photo')}}</ui-button>
      <div class="text-center">{{$t('personal-photo.max_mb_jpeg_or_png_format')}}</div>
    </div>

    <filling-footer @prev="prev" @next="next" last_step :isDisabled="avatarLoading"></filling-footer>
  </div>
</template>

<script>
import AvatarCropper from 'vue-avatar-cropper';
import FillingHeader from '../../components/filling/FillingHeader';
import UiButton from '../../components/ui/UiButton';
import UiLoader from '../../components/ui/UiLoader';
import FillingFooter from '../../components/filling/FillingFooter';
import config from '../../config';
import mapFields from '../../utils/mapFields';

export default {
  name: 'personal-photo',
  components: {
    FillingFooter,
    UiButton,
    FillingHeader,
    AvatarCropper,
    UiLoader
  },
  data() {
    return {
      labels: { submit: 'Submit', cancel: 'Cancel' },
      uploadHeaders: {
        Authorization: `Bearer ${localStorage['feathers-jwt']}`
      },
      uploadUrl: `${config.backendUrl}/profile-photos`,
      progress: 0,
      uploadOutputOptions: {
        width: 1024,
        height: 1024
      },
      isStepDone: false,
      avatarLoading: false,
    };
  },
  computed: {
    ...mapFields({
      studentId: 'auth.user.student.id',
      photoPath: 'auth.user.photo.key',
      photoPathSM: 'auth.user.photoSmall.key'
    }),
    avatarUrl() {
      if (this.photoPath) {
        return `${config.backendUrl}/${this.photoPath}?token=${localStorage['feathers-jwt']}`;
      }
      return undefined;
    }
  },
  async created() {
    // Check has status or not
    const studentStatuses = await this.$store.dispatch('student-statuses/find', {
      query: {
        studentId: this.studentId
      }
    });

    if (studentStatuses.total) {
      this.isStepDone = true;
    }
  },
  methods: {
    handleSubmit() {
      this.avatarLoading = true;
    },
    handlePhotoLoad() {
      console.log('from img: loaded');
      this.avatarLoading = false;
    },
    handlePhotoLoadError(e) {
      console.log('from img: error', e);
      this.avatarLoading = false;
    },
    handleUploaded(photosArr) {
      console.log('from avatar cropper: loaded', photosArr);
      if (photosArr.length && photosArr[0] && photosArr[1]) {
        this.photoPath = photosArr[0].key;
        this.photoPathSM = photosArr[1].key;
      }
    },
    handleError(defaultMessage, _, context) {
      this.avatarLoading = false;
      const { response } = context;
      const serverMessage = response && typeof response === 'string' ? JSON.parse(response).message : defaultMessage;
      this.$notify.error(serverMessage);
    },
    prev() {
      this.$router.push({ path: '/filling/professional-details' });
    },
    async next() {
      global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'application_upload_personal_photo');
      this.$router.push({ path: '/status' });
    }
  }
};
</script>

<style lang="scss">
.personal-photo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mxw-280 {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .mnw-280 {
    min-width: 280px;
  }

  .mnh-280 {
    min-height: 280px;
  }

  .br50 {
    border-radius: 50%;
  }

  .avatar-cropper-container {
    padding: 8px;
    border-radius: 4px;
    margin: 8px;
  }
  .avatar-cropper-btn {
    border-radius: 4px;
    margin-top: 16px;
    margin-right: 8px;
    color: #fff !important;
    background-color: #1890ff !important;
  }
  .avatar-cropper-btn:last-child {
    margin-right: 0px;
  }
  .avatar-cropper-btn:hover {
  }

  .loader {
    width: 280px;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
