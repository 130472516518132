<template>
  <div class="filling_header mb-8">
    <div v-if="done" class="done flex-center"></div>
    <div v-else class="icon flex-center">{{icon}}</div>
    <div class="title">{{title}}</div>
    <div class="step">Step {{step}}</div>
  </div>
</template>

<script>
  export default {
    name: 'FillingHeader',
    props: {
      icon: {
        type: String,
        default: '1',
      },
      title: {
        type: String,
        default: '-',
      },
      step: {
        type: String,
        default: '1/1',
      },
      done: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style scoped lang="scss">
  .filling_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin-top: -12px;

    .done, .icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 16px;
    }

    .done {
      background-color: #258FFB;
      background-image: url("~@/assets/images/done.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }

    .icon {
      color: #FFFFFF;
      background-color: #7D7D7D;
    }

    .title {
      flex-grow: 1;
      margin-left: 8px;
      font-weight: 500;
      font-size: 18px;
      color: #383838;
    }
  }
</style>
