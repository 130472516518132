<template>
  <div class="filling_footer">
    <ui-button v-show="first_step === false" class="mr" @click="$emit('prev')" tr_garay>
      {{$t('FillingFooter.previous_step')}}
    </ui-button>
    <ui-button v-if="last_step" @click="$emit('next')" :disabled="isDisabled">{{$t('FillingFooter.apply')}}</ui-button>
    <ui-button v-else @click="$emit('next')">{{$t('FillingFooter.next')}}</ui-button>
  </div>
</template>

<script>
  import UiButton from '../ui/UiButton';

  export default {
    name: 'FillingFooter',
    components: { UiButton },
    props: {
      first_step: {
        type: Boolean,
        default: false,
      },
      last_step: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style scoped lang="scss">
  .filling_footer {
    margin-top: 32px;
    display: flex;
    .mr {
      margin-right: 16px;
    }
  }
</style>
